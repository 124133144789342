import React from 'react';
import ReactDOM from 'react-dom';
import Counter from './components/Counter';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import reducer from './reducers';
import reportWebVitals from './reportWebVitals';


const store = createStore(reducer);

//Provider（供給する）でラップすることで、stateの情報を共有することが可能
ReactDOM.render(
    <Provider store={store}>
        <Counter />
    </Provider>, 
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

